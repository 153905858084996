<template>
  <div>
    <titleDiv></titleDiv>
    <div class="content">
      <div class="container">
        <h1 class="heading">常见问题</h1>
        <p class="text">以下是一些用户常见问题</p>
        <p class="text">如果您在这里找不到解决问题的方法，请随时与我们联系</p>
      </div>
      <div class="container">
        <h2 class="title">短信相关</h2>
        <div class="container-div">
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
        </div>
      </div>
      <div class="container">
        <h2 class="title">核销问题</h2>
        <div class="container-div">
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
          <div class="question">
            <h3>问题1</h3>
            <p class='text text-left'>答复11</p>
          </div>
        </div>
      </div>
    </div>
    <footerDiv></footerDiv>
  </div>
</template>

<script>
  import titleDiv from '../index/title';
  import footerDiv from '../index/footer'

  export default {
    name: 'faq',
    components: {
      titleDiv,
      footerDiv
    }
  }
</script>

<style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  .container {
    padding: 3rem 1.5rem;
    margin: 0 auto;
  }

  .content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 60px;
  }

  .heading {
    font-size: 36px;
  }

  .text {
    font-size: 17px;
    color: #6d7d93;
    line-height: 26px;
  }

  .container-div {
    display: flex;
    flex-wrap: wrap;
  }

  .title {
    text-align: left;
    font-size: 28px;
    line-height: 42px;
    color: #546496;
    border-left: 5px solid #507ce8;
    padding-left: 10px;
    margin-bottom: 30px;
  }

  .question {
    width: 50%;
    box-sizing: border-box;
    padding: 30px;
  }

  h3 {
    text-align: left;
    color: #44485b;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .text-left {
    text-align: left;
  }

  .text-left > a {
    color: #3273dc;
  }

  @media screen and (max-width: 798px) {
    .question {
      width: auto;
      padding: 30px 0;
    }
    .container {
      padding: 3rem 1rem;
    }
    .content {
      padding: 20px 30px;
    }
  }
</style>